<template>
  <div>
    <v-card flat>
      <v-col cols="12" lg="12" class="pl-9">
        <v-radio-group
          v-model="radios"
          mandatory
          @change="handlePaymentMethodChange"
        >
          <v-row align="center" justify="left">
            <v-col cols="auto" class="d-flex align-center">
              <v-radio color="success" value="paypal"></v-radio>
              <img
                src="@/assets/images/paypal-icon.png"
                width="120"
                height="50"
                alt="paypal"
              />
            </v-col>
            <v-col cols="auto" class="d-flex align-center">
              <v-radio color="primary" value="stripe"></v-radio>
              <img
                src="@/assets/images/stripe.png"
                width="120"
                height="50"
                alt="stripe"
              />
            </v-col>
          </v-row>
        </v-radio-group>
      </v-col>

      <v-col cols="12" lg="12" class="py-4 pl-6">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" lg="3" md="4">
              <v-text-field
                append-icon="monetization_on"
                label="Available wallet Balance"
                v-model="payment.balance"
                type="text"
                outlined
                disabled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4">
              <v-text-field
                append-icon="account_balance_wallet"
                label="Enter Amount"
                v-model="payment.price"
                :rules="amountRules"
                outlined
                dense
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2" md="2">
              <v-card-actions class="py-0">
                <v-spacer></v-spacer>
                <v-btn
                  @click="submitAmount"
                  class="myTitle text-capitalize primary-btn"
                  block
                  outlined
                  dark
                >
                  Make Payment
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-card>
    <v-dialog v-model="paymentDialogEdit" max-width="400">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              <span class="headline font-weight-bold">Add Funds</span>
            </v-col>
            <v-col class="text-right">
              <v-btn icon @click="closeEdit">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0 mb-0">
          <label>Topup amount (USD)</label>
          <v-text-field
            v-model="payment.price"
            outlined
            disabled
          ></v-text-field>
          <v-row>
            <v-col>
              <v-label
                >{{ paymentMethod }} Transaction Fee: ${{
                  calculateTransactionFee()
                }}</v-label
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="closeEdit"
            class="bordered-btn"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-label class="mr-2">
            <span class="font-weight-bold"
              >Total: ${{
                (
                  parseFloat(this.payment.price) +
                  parseFloat(calculateTransactionFee())
                ).toFixed(2)
              }}
            </span>
          </v-label>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="makePayment()">
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loading :active="isLoading" :loader="loader" />
  </div>
</template>
<style scoped>
.bordered-btn {
  border: 1px solid #2196f3; /* Adjust the color as needed */
  color: #2196f3; /* Adjust the color as needed */
}

.mt-4 {
  margin-top: 16px;
}
</style>

<script>
import billingAccountAPI from "@/services/billingAccountAPI.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "billingAcc",
  components: {
    Loading,
  },
  data() {
    return {
      valid: true,
      notification: true,
      topUp: false,
      showTopUp: false,
      payment: {},
      radios: null,
      loader: "bars",
      isLoading: false,
      amountRules: [(v) => !!v || "Amount is required"],
      paymentDialogEdit: false,
      fundaText: "",
      paymentMethod: "Paypal",
    };
  },
  mounted() {
    // this.getBilling();
    this.payment.balance = localStorage.getItem("getBalance");
  },
  methods: {
    handlePaymentMethodChange() {
      this.paymentMethod = this.radios === "paypal" ? "Paypal" : "Stripe";
    },
    calculateTransactionFee() {
      // Assuming totalAmount is a number, you can adjust the calculation as needed
      const transactionFee = this.payment.price * 0.05; // 5 percent
      return transactionFee.toFixed(2); // Format to two decimal places
    },
    async makePayment() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        if (this.paymentMethod.toLowerCase() == "paypal") {
          let paymentData = {
            checkOutPaymentId: "EXT_PAYPAL",
            price: this.payment.price,
            shippingMethod: "NO_SHIPPING@_NA_",
          };
          try {
            let response = await billingAccountAPI.addAmountToCart(paymentData);
            // console.log("response", response.orderId);
            if (response.orderId) {
              localStorage.setItem("orderId", response.orderId);
              var orderIdData = {
                orderId: response.orderId,
              };
              let responseCallPayPalData = await billingAccountAPI.callPayPalEvent(
                orderIdData
              );
              // console.log("response---BillingACc", responseCallPayPalData);
              if (responseCallPayPalData.redirectString) {
                this.isLoading = true;
                location.href = responseCallPayPalData.redirectString;
              }
            }
          } catch (err) {
            this.isLoading = false;
            console.log(err);
          }
        } else {
          let paymentData = {
            amount: this.payment.price,
          };

          try {
            let response = await billingAccountAPI.createStripePrice(
              paymentData
            );
            console.log("REsponse", response);
            if (response?.responseMessage == "success") {
              // localStorage.setItem("orderId", response.orderId);
              var priceData = {
                priceId: response?.responseData?.id,
                amount: this.payment.price,
              };
              let responseStripeSession = await billingAccountAPI.createStripeSessions(
                priceData
              );
              if (responseStripeSession?.responseMessage == "success") {
                this.isLoading = true;
                location.href = responseStripeSession?.responseData?.url;
              }
            }
          } catch (err) {
            this.isLoading = false;
            console.log(err);
          }
        }
      }
    },
    async closeEdit() {
      this.paymentDialogEdit = false;
    },

    async submitAmount() {
      if (!this.payment.price || this.payment.price < 5) {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Please add amount more than 5",
          color: "error",
        });
      } else {
        this.paymentDialogEdit = true;
      }
    },
  },
};
</script>
